import React, { useState } from "react";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/auth";
import Editor from "../../../components/Editor";

const TermsEdit = ({
  setShowEditModal,
  terms,
  setEditTerId,
  setEditTermsId,
}) => {
  const [englishTermsName, setEnglishTermsName] = useState(
    terms?.title?.english
  );
  const [amharicTermsName, setAmharicTermsName] = useState(
    terms?.title?.amharic
  );
  const [termsEnglishDescription, setFaqEnglishDescription] = useState(
    terms?.description?.english
  );
  const [termsAmharicDescription, setFaqAmharicDescription] = useState(
    terms?.description?.amharic
  );

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();


  const editTermsMutation = useMutation(
    async (editTerms) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/term-and-conditions/${editTerms?.id}`,
          formData,
        config
      )
  );

  const editTermsHandler = async (newData) => {
    console.log({ englishTermsName });

    try {
      formData.append("nameEnglish", englishTermsName);
      formData.append("nameAmharic", amharicTermsName);
      formData.append("descriptionEnglish", termsEnglishDescription);
      formData.append("descriptionAmharic", termsAmharicDescription);
      formData.append("_method", "patch");


      editTermsMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditTerId(null);
            toast.success("success");
            setShowEditModal(false);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      englishName: terms?.title?.english,
      amharicName: terms?.title?.english,
      englishDesc: terms?.description?.english,
      amharicDesc: terms?.description?.amharic,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("English Name is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDesc: Yup.string().required("English Description  is required"),
      amharicDesc: Yup.string().required("Amharic Description  is required"),
    }),
    onSubmit: (values) => {
      editTermsHandler(terms?.id);
      setEditTermsId(terms?.id);
      setEditTerId(1);
      console.log(englishTermsName);
    },
  });
  return (
    <>
      <div className="justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-7xl max-h-[80%]">
          {/*content*/}
          <form
            className=" px-8 pt-6 pb-8 w-full"
            onSubmit={formik.handleSubmit}
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Edit Terms</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowEditModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-col">
                <div className="m-4">
                  <div className="felx flex-col">
                    <div className="h-[50%] mb-4">
                      <label className="block text-black text-sm font-base mb-1">
                        English Terms Name
                      </label>
                      <input
                        id="englishName"
                        defaultValue={terms?.title?.english}
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        onChange={(e) => {
                          formik.handleChange(e);
                          setEnglishTermsName(e.target.value);
                        }}
                      />
                      {formik.touched.englishName &&
                      formik.errors.englishName ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishName}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>{" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Terms English Description
                    </label>

                    <Editor
                      id="englishDesc"
                      value={formik.values.englishDesc}
                      setValue={(val) => {
                        setFaqEnglishDescription(val);
                        formik.setFieldValue("englishDesc", val);
                      }}
                    />
                  </div>
                  {formik.touched.englishDesc && formik.errors.englishDesc ? (
                    <div className="text-[13px] font-medium capitalize mt-4 text-red-500">
                      {formik.errors.englishDesc}{" "}
                    </div>
                  ) : null}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Amharic Terms Name
                    </label>
                    <input
                      id="amharicName"
                      defaultValue={terms?.title?.amharic}
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setAmharicTermsName(e.target.value);
                      }}
                    />
                    {formik.touched.amharicName && formik.errors.amharicName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicName}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base  mb-1 pt-6">
                      Terms Amharic Description
                    </label>
                    <Editor
                      id="amharicDesc"
                      value={formik.values.amharicDesc}
                      setValue={(val) => {
                        setFaqAmharicDescription(val);
                        formik.setFieldValue("amharicDesc", val);
                      }}
                    />
                  </div>
                  {formik.touched.amharicDesc && formik.errors.amharicDesc ? (
                    <div className="text-[13px] font-medium capitalize mt-4 text-red-500">
                      {formik.errors.amharicDesc}{" "}
                    </div>
                  ) : null}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={editTermsMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {editTermsMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <ToastContainer />
    </>
  );
};

export default TermsEdit;
