import React, { useState } from "react";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/auth";
import Editor from "../../../components/Editor";

const PrivacyAdd = ({ setShowModal }) => {
  const [englishPrivacyName, setEnglishPrivacyName] = useState();
  const [amharicPrivacyName, setAmharicPrivacyName] = useState();
  const [privacyEnglishDescription, setPrivacyEnglishDescription] = useState();
  const [privacyAmharicDescription, setPrivacyAmharicDescription] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const addPrivacyHandler = () => {
    addPrivacyMutationSubmitHandler();
  };

  const addPrivacyMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/privacy-policies`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addPrivacyMutationSubmitHandler = async (values) => {
    let formData = new FormData();

    formData.append("titleEnglish", englishPrivacyName);
    formData.append("titleAmharic", amharicPrivacyName);
    formData.append("descriptionEnglish", privacyEnglishDescription);
    formData.append("descriptionAmharic", privacyAmharicDescription);
    try {
      addPrivacyMutation.mutate(formData, {
        onSuccess: () => {
          setShowModal(false);
          toast.success("Add success!");
          setShowModal(false);
          setEnglishPrivacyName(null)
          setAmharicPrivacyName(null)
          setPrivacyEnglishDescription(null)
          setPrivacyAmharicDescription(null)
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "catPrivacy add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      englishName: "",
      amharicName: "",
      englishDesc: "",
      amharicDesc: "",
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("English Name is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDesc: Yup.string().required("English Description  is required"),
      amharicDesc: Yup.string().required("Amharic Description  is required"),
    }),
    onSubmit: (values) => {
      addPrivacyHandler();
    },
  });
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-8xl max-h-[80%]">
          {/*content*/}
          <form
            className=" px-8 pt-6 pb-8 w-full"
            onSubmit={formik.handleSubmit}
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Privacy</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-col">
                <div className="felx flex-col ">
                  <div className="h-[50%]">
                    <div className="m-4">
                    <label className="block text-black text-sm font-base mb-1">
                      English Privacy Name
                    </label>
                    <input
                      id="englishName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setEnglishPrivacyName(e.target.value);
                      }}
                    />
                    {formik.touched.englishName && formik.errors.englishName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.englishName}{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                  {" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Privacy English Description
                    </label>

                    <Editor
                      id="englishDesc"
                      value={formik.values.englishDesc}
                      setValue={(val) => {
                        setPrivacyEnglishDescription(val);
                        formik.setFieldValue("englishDesc", val);
                      }}
                    />
                  </div>
                  {formik.touched.englishDesc && formik.errors.englishDesc ? (
                    <div className="text-[13px] font-medium capitalize mt-4 text-red-500">
                      {formik.errors.englishDesc}{" "}
                    </div>
                  ) : null}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Amharic Privacy Name
                    </label>
                    <input
                      id="amharicName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setAmharicPrivacyName(e.target.value);
                      }}
                    />
                    {formik.touched.amharicName && formik.errors.amharicName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicName}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base  mb-1 pt-6">
                      Privacy Amharic Description
                    </label>
                    <Editor
                      id="amharicDesc"
                      value={formik.values.amharicDesc}
                      setValue={(val) => {
                        setPrivacyAmharicDescription(val);
                        formik.setFieldValue("amharicDesc", val);
                      }}
                    />
                  </div>
                  {formik.touched.amharicDesc && formik.errors.amharicDesc ? (
                    <div className="text-[13px] font-medium capitalize mt-4 text-red-500">
                      {formik.errors.amharicDesc}{" "}
                    </div>
                  ) : null}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={addPrivacyMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {addPrivacyMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <ToastContainer />
    </>
  );
};

export default PrivacyAdd;
