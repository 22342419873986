import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/auth";

const EditProfile = ({ ProfileData, handleCloseUpProf }) => {
  const [name, setName] = useState(ProfileData?.name);
  const [email, setEmail] = useState(ProfileData?.email);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editProfileMutation = useMutation(
    async (editProfile) =>
      await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          `/update-profile/${editProfile?.id}`,
        formData,
        config
      )
  );

  const editProfileHandler = async (newData) => {
    console.log({ name });

    try {
      formData.append("name", name);
      formData.append("email", email);

      editProfileMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("success");
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: ProfileData?.name,
      email: ProfileData?.email,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Edit is required"),
    }),
    onSubmit: (values) => {
      editProfileHandler(ProfileData?.id);
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6  mx-auto max-w-4xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className=" relative flex flex-col w-[100%] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit Profile</h3>
                  <button
                    className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleCloseUpProf()}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-20 flex-auto">
                  <label className="block text-black text-sm font-base mb-1">
                    Name
                  </label>
                  <input
                    id="name"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={ProfileData?.name}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setName(e.target.value);
                    }}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.name}{" "}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    email
                  </label>
                  <input
                    id="email"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={ProfileData?.email}
                    onChange={(e) => {
                      formik.handleChange(e);

                      setEmail(e.target.value);
                    }}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.email}{" "}
                    </div>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleCloseUpProf()}
                  >
                    Close
                  </button>
                  <button
                    disabled={editProfileMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editProfileMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditProfile;
