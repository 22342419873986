import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/auth";
import { Box, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Otp = ({ handleCloseOtp, prevPhone, setPrevPhone }) => {
   const navigate = useNavigate()
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState(prevPhone);
 

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addOtpHandler = () => {
    addOtpMutationSubmitHandler();
  };
  const resendOtpHandler = () => {
    resendOtpMutationSubmitHandler();
  };

  const resendOtpMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}resend-otp`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  const addOtpMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}verify-otp`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const resendOtpMutationSubmitHandler = async (values) => {
 

    try {
      resendOtpMutation.mutate( {
        onSuccess: () => {
           
          toast.success("success", {});
         

       
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "resend otp failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const addOtpMutationSubmitHandler = async (values) => {
    let formData = new FormData();
    formData.append("otp", otp);
   
    formData.append("phone", phone);

    try {
      addOtpMutation.mutate(formData, {
        onSuccess: () => {
          toast.success("success", {});
          navigate("/login")
          handleCloseOtp();
          setOtp(null)
          setPrevPhone(null)
          
       
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "otp add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      otp: "",
     
      phone: prevPhone,
    
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Otp is required"),
  
      phone: Yup.string().required("phone is required"),
    
    }),
    onSubmit: (values) => {
      addOtpHandler();
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center  flex  overflow-y-auto fixed inset-0 outline-none focus:outline-none">
          <div className="relative w-auto my-6  mx-auto max-w-4xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className=" relative flex flex-col w-[100%] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Verify Otp</h3>
                </div>
                {/*body*/}
                <div className="relative p-20 flex-auto">
                <label className="block text-black text-sm font-base mb-1">
                    Phone
                  </label>
                  <input
                    id="phone"
                    defaultValue={prevPhone}
                    type="number"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    onChange={(e) => {
                      formik.handleChange(e);

                      setPhone(e.target.value);
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.phone}{" "}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    <br/>
                    Otp
                  </label>
                  <input
                    id="otp"
                    className="shadow appearance-none border rounded w-88 py-2 px-10 text-black"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setOtp(e.target.value);
                    }}
                  />
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.otp}{" "}
                    </div>
                  ) : null}
               
               <button
                className="text-blue-400 hover:text-blue-800 px-2"
                onClick={() => resendOtpHandler()}
              >
                Resend otp
              </button>
               
             

                </div>
                {/*footer*/}
              
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleCloseOtp()}
                  >
                    Close
                  </button>
                  <button
                    disabled={addOtpMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {addOtpMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default Otp;
