import React from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../context/auth";

const BannerView = ({ banner, setShowViewModal, setViewBannerId }) => {
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ViewBannerMutation = useMutation(
    async (deleteBanner) =>
      await axios.show(
        process.env.REACT_APP_BACKEND_URL + `admin/banners/${deleteBanner?.id}`,
        {
          headers,
          enabled: !!token,
        }
      ),
    {
      retry: false,
    }
  );

  const ViewBannerHandler = async (newData) => {
    try {
      ViewBannerMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: (responseData) => {
            setViewBannerId(null);
          },
          onError: (responseData) => {},
        }
      );
    } catch (err) {}
  };
  return (
    <div>
      {" "}
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">View Banner</h3>
            </div>
            {/*body*/}
            <div className="flex flex-row">
              <div className="relative p-6 flex-auto">
                <div className="relative flex-auto">
                  <p className="text-xl py-2 text-bold">
                    <b> Banner English title:</b> <span />
                    {banner?.title?.english}
                  </p>
                </div>
                <div className="relative  py-2 flex-auto">
                  <p className="text-xl text-bold">
                    <b> Banner Amharic title:</b> <span />
                    {banner?.title?.amharic}
                  </p>
                </div>{" "}
                <div className="relative  flex-auto">
                  <p className="text-sm ">
                    created at: <span />
                    {banner?.created_at}
                  </p>
                </div>
              </div>
              <div className="relative p-6 flex-auto overflow-auto ">
                <img className="" src={banner?.banner_image} />
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowViewModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default BannerView;
