import React from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../../../context/auth";
import Editor from "../../../components/Editor";
import ReactQuill from "react-quill";

const TermsView = ({ terms, setShowViewModal, setViewTermsId }) => {
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ViewTermsMutation = useMutation(
    async (viewTerms) =>
      await axios.show(
        process.env.REACT_APP_BACKEND_URL +
          `admin/term-and-conditions/${viewTerms?.id}`,
        {
          headers,
          enabled: !!token,
        }
      ),
    {
      retry: false,
    }
  );

  const ViewTermsHandler = async (newData) => {
    try {
      ViewTermsMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: (responseData) => {
            setViewTermsId(null);
          },
          onError: (responseData) => {},
        }
      );
    } catch (err) {}
  };
  return (
    <div>
      {" "}
      <div className="justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-[80%] my-6 mx-6 max-h-[80%] ">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">View Terms</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowViewModal(false)}
              >
                <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="relative p-6 flex-auto">
                  <div className="relative p-6 flex-auto ">
                    <p className="text-xl py-2 text-bold">
                      <b> Terms English title:</b> <span />
                      {terms?.title?.english}
                    </p>
                  </div>
                  <div className="relative flex-auto h-auto w-auto border">
                    <p className="text-l  text-bold">
                      <b> Terms English Description:</b> <span />
                      <ReactQuill
                        value={terms?.description?.english}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </p>
                  </div>
                </div>{" "}
                <div className="p-6">
                  <div className="relative  p-6  flex-auto">
                    <p className="text-xl  py-2 text-bold">
                      <b> Terms Amharic title:</b> <span />
                      {terms?.title?.amharic}
                    </p>
                  </div>{" "}
                  <div className="relative  py-2 flex-auto h-auto w-auto border">
                    <p className="text-l text-bold">
                      <b> Terms Amharic Description:</b> <span />
                      <ReactQuill
                        value={terms?.description?.amharic}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </p>
                  </div>{" "}
                  <div className="relative  flex-auto">
                    <p className="text-sm ">
                      created at: <span />
                      {terms?.created_at}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowViewModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default TermsView;
