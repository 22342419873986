import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { useAuth } from "../context/auth";
import Logo from "../assets/Logo";
import { FaUser, FaUserAlt } from "react-icons/fa";
import Dropdown from "muicss/lib/react/dropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DropdownItem from "muicss/lib/react/dropdown-item";
import { Button, Fade, IconButton, Menu, MenuItem } from "@mui/material";
import { useQuery } from "react-query";
import axios from "axios";
import Drawers from "../pages/auth/Drawer";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ProfileData = useQuery(
    ["ProfileDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}user`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  return (
    <div className="flex flex-row">
      <div className="w-11/12 flex flex-row ">
        <div className="flex  w-[100%]">
          <div className="md:hidden w-[20%] pt-6  ">

          <Drawers/>
          </div>
          <h2 class="   text-xl pl-4 md:pl-6 pt-8 font-extrabold leading-none tracking-tight text-black ">
            Yeabrak Gebeya
          </h2>
        </div>
      </div>

      <div className="flex justify-end w-1/8  mr-4 mt-4">
        <div className=" text-sm  flex flex-row" >
          <div className=" hidden md:grid mt-5">
          {ProfileData.isError ? (logout()): (<>
            {ProfileData?.data?.data?.data?.name}
          </>)}
          </div>
          <Button
            variant="text"
            style={{
              color: "black",
              borderRadius: 64,

              padding: "16px ",
              margin: "0px",
            }}
            aria-controls={open1 ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick}
          >
            <FaUser className=" text-2xl" />
          </Button>

          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose}
            TransitionComponent={Fade}
            
          >
            <MenuItem onClick={()=> navigate("/profile")}>Profile</MenuItem>
           
          
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>

        {/* <button
          onClick={logout}
          type="submit"
          className="text-white  bg-[#636ab1] hover:bg-red-800 rounded shadow-sm px-4 pt-1 mt-4 pb-2  mr-4"
        >
          Logout
        </button> */}
      </div>
    </div>
  );
};

export default NavBar;
