import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/auth";

const BannerEdit = ({
  showEditModal,
  setShowEditModal,
  banner,
  id,
  setEditBannerId,
  setEditBanId,
}) => {
  const [englishBannerName, setEnglishBannerName] = useState(
    banner?.title?.english
  );
  const [amharicBannerName, setAmharicBannerName] = useState(
    banner?.title?.amharic
  );

  const [editBanner, setEditBanner] = useState();
  const [bannerImage, setBannerImage] = useState(banner?.banner_image);
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editBannerMutation = useMutation(
    async (editBanner) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `admin/banners/${editBanner?.id}`,
        formData,
        config
      )
  );
  const editBannerHandler = async (newData) => {
    console.log({ banner });

    try {
      formData.append("titleEnglish", englishBannerName);
      formData.append("titleAmharic", amharicBannerName);
      formData.append("banner_image", bannerImage);
      formData.append("_method", "patch");

      editBannerMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("Edit success!");
            setShowEditModal(false);
            setEditBanId(null);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      englishName: banner?.title?.english,
      amharicName: banner?.title?.amharic,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
    }),
    onSubmit: (values) => {
      editBannerHandler(banner?.id);
      setEditBanner(banner?.id);
      setEditBannerId(1);
    },
  });

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative h-[80%] w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <form
            className=" px-8 pt-6 pb-8 w-full"
            onSubmit={formik.handleSubmit}
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Edit Banner</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowEditModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="felx flex-row w-80">
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      English Banner Name
                    </label>
                    <input
                      id="englishName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      defaultValue={banner?.title?.english}
                      onChange={(e) => {
                        formik.handleChange(e);

                        setEnglishBannerName(e.target.value);
                      }}
                    />
                    {formik.touched.englishName && formik.errors.englishName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.englishName}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Amharic Banner Name
                    </label>
                    <input
                      id="amharicName"
                      defaultValue={banner?.title?.amharic}
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setAmharicBannerName(e.target.value);
                      }}
                    />
                    {formik.touched.amharicName && formik.errors.amharicName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicName}
                      </div>
                    ) : null}
                  </div>
                  <label
                    class="block mb-2 text-sm font-medium "
                    for="file_input"
                  >
                    Banner Image file
                  </label>

                  <img className="w-96 p-6" src={banner?.banner_image} />

                  <input
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    onChange={(e) => {
                      setBannerImage(e.target.files[0]);
                    }}
                    type="file"
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={editBannerMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {editBannerMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default BannerEdit;
