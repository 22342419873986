import {
  CssBaseline,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineArrowBack } from "react-icons/md";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/auth";

const ProductsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ProductData = useQuery(
    ["ProductDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}products/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ProductData]);

  console.log(ProductData)
  return (
    <>
      {ProductData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {ProductData.isFetching ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <button>
            <MdOutlineArrowBack
              onClick={() => navigate(`/products`)}
              className="text-[#636ab1] text-4xl"
            />
          </button>

          <div className="pl-6 pt-6 flex justify-center">
            <br />
            <br />
            <ImageList
              sx={{ width: 350, height: 250 }}
              cols={1}
              rowHeight={164}
            >
              {ProductData?.data?.data?.data?.product?.product_images?.map((item) => {
                return (
                  <ImageListItem key={item}>
                    <img
                      src={`${item}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </div>
          <div className="flex flex-col">
            <div className="grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col">
                (English)
                <div className="text-3xl">
                  {ProductData?.data?.data?.data?.product?.name?.english}
                  <br />
                </div>
                <br />
                <div>
                  <b> Description: </b>
                  {ProductData?.data?.data?.data?.product?.description?.english}
                  <br />
                </div>
              </div>
              <div className="flex justify-end  ">
                <div className="flex flex-col  ">
                  (Amharic)
                  <div className="text-3xl">
                    {ProductData?.data?.data?.data?.product?.name?.amharic}
                    <br />
                  </div>
                  <br />
                  <div>
                    <b>Description: </b>
                    {ProductData?.data?.data?.data?.product?.description?.amharic}
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className=" grid md:grid-cols-3 border-t border-black">
              <div className="flex flex-col ">
                <div>
                  <b>Brand: </b>
                  {ProductData?.data?.data?.data?.product?.brand}
                  <br />
                </div>
                <div>
                  <b>Price: </b>
                  {ProductData?.data?.data?.data?.product?.price}
                  <br />
                  <b>discount: </b>
                  {ProductData?.data?.data?.data?.product?.discount}
                  <br />
                </div>
                <div>
                  <b>Rating: </b>
                  {ProductData?.data?.data?.data?.product?.rating}
                  <br />
                  <b>number of reviews: </b>
                  {ProductData?.data?.data?.data?.product?.number_of_reviews}
                  <br />
                </div>{" "}
              </div>
              <div className=" flex ">
                <div className=" flex  flex-col  ">
                
                  <div>
                    <b>Status: </b>
                    {ProductData?.data?.data?.data?.product?.status}
                    <br />{" "}
                  </div>
                  <div>
                    <b>Has discount: </b>
                    {ProductData?.data?.data?.data?.product?.has_discount == "true"
                      ? "true"
                      : "false"}
                    <br />
                    <b>Discount percent: </b>
                    {ProductData?.data?.data?.data?.product?.discount_percent}
                    <br />
                  </div>
                  <div>
                    <b>On cart: </b>
                    {ProductData?.data?.data?.data?.product?.on_cart == "true"
                      ? "true"
                      : "false"}
                    <br />
                  </div>{" "}
                </div>
              </div>
              <div className="  flex flex-col ">
                <div>
                  <b>Owner: </b>
                  {ProductData?.data?.data?.data?.product?.owner?.name}
                  <br />
                  <b>email: </b>
                  {ProductData?.data?.data?.data?.product?.owner?.email}
                  <br />
                </div>
                <div>
                  <b>phone: </b>
                  {ProductData?.data?.data?.data?.product?.owner?.phone}
                </div>{" "}
                <div>
                  <b>status: </b>
                  {ProductData?.data?.data?.data?.product?.owner?.status}
                  <br />
                </div>
                <div>
           
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsView;
