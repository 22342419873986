import React, { useState } from "react";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";

import TermsDelete from "./TermsDelete";
import TermsEdit from "./TermsEdit";
import TermsView from "./TermsView";
import { AiFillEye } from "react-icons/ai";

const TermsTable = ({
  terms,
  id,
  setDeleteTermsId,
  setEditTermsId,
  setViewTermsId,
  setEditTerId,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  return (
    <tr key={id} class="bg-white border-b ">
      <td class="px-6 py-4">
        <p>{terms?.title?.english}</p>
      </td>
      <td class="px-6 py-4">
        <p>{terms?.title?.amharic}</p>
      </td>

      <td class="px-6 py-4">
        <div className="flex justify-end">
          <div>
            {showDeleteModal ? (
              <>
                <TermsDelete
                  terms={terms}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeleteTermsId={setDeleteTermsId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showEditModal ? (
              <>
                <TermsEdit
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  terms={terms}
                  id={id}
                  setEditTermsId={setEditTermsId}
                  setEditTerId={setEditTerId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showViewModal ? (
              <>
                <TermsView
                  terms={terms}
                  setShowViewModal={setShowViewModal}
                  setViewTermsId={setViewTermsId}
                />
              </>
            ) : null}
          </div>
          <div className="w-32">
            <button
              onClick={() => {
                setShowViewModal(true);
                setViewTermsId(terms?.id);
              }}
            >
              <AiFillEye
                onClick={() => {
                  setShowViewModal(true);
                  setViewTermsId(terms?.id);
                }}
                className="text-gray-500 mr-2 text-2xl"
              />
            </button>
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditTermsId(terms?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteTermsId(terms?.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TermsTable;
