import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/home/Dashboard";
import { Bars } from "react-loader-spinner";
import { useAuth } from "./context/auth";
import NavBar from "./components/NavBar";
import SideBar from "./components/SideBar";
import GoogleFontLoader from "react-google-font-loader";

import { toast, ToastContainer } from "react-toastify";

import PaymentMethods from "./pages/Ecommerce/payment methods/PaymentMethods";
import Orders from "./pages/Ecommerce/orders/Orders";
import Products from "./pages/Ecommerce/products/Products";

import Banner from "./pages/banner/Banner";

import Unit from "./pages/Ecommerce/product unit/Unit";
import OrderView from "./pages/Ecommerce/orders/OrderView";
import Accounts from "./pages/Ecommerce/accounts/Accounts";
import ProductsView from "./pages/Ecommerce/products/ProductsView";
import Privacy from "./pages/Settings/privacy/Privacy";
import Terms from "./pages/Settings/terms/Terms";
import FAQ from "./pages/Settings/faqs/FAQ";
import Profile from "./pages/auth/Profile";
import Drawer from "./pages/auth/Drawer";
import Drawers from "./pages/auth/Drawer";

const App = () => {
  const { token, user, checked } = useAuth();
  function LoginComp() {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  function HomeComp() {
    return (
      <>
        {" "}
        <GoogleFontLoader
          fonts={[
            {
              font: "Poppins",
              weights: [300, "400i"],
            },
          ]}
        />
        <div className="" style={{ fontFamily: "Poppins" }}>
          <div className=" ">
            <div className=" bg-gray-200 flex flex-row h-[100vh] overflow-y-scroll ">
              <div className=" hidden md:grid w-[25%] shadow-sm  bg-white  ">
                <SideBar />
              </div>
              <div className="  w-[100%] ">
                <div className=" rounded-lg ">
                    
                  <div className="h-20  mx-2 mt-8 border-t  md:mx-8 bg-white shadow-sm pb-4">

                    <NavBar />
                  </div>

                  <div className=" mt-8 m-2 md:m-8  p-2 md:p-8 bg-white overflow-scroll">
                    <Routes>
                      {" "}
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/accounts" element={<Accounts />} />
                      <Route
                        path="/payment-methods"
                        element={<PaymentMethods />}
                      />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/orders/:id" element={<OrderView />} />
                      <Route path="/products" element={<Products />} />
                      <Route path="/products/:id" element={<ProductsView />} />
                      <Route path="/units" element={<Unit />} />
                      <Route path="/banner" element={<Banner />} />
                      <Route path="/faqs" element={<FAQ />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/terms" element={<Terms />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/drawer" element={<Drawers />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function RoutComp() {
    if (token && user) {
      return (
        <>
          <HomeComp />
        </>
      );
    } else {
      return <LoginComp />;
    }
  }
  return (
    <div>
      <>
        {checked ? (
          <RoutComp />
        ) : (
          <div className="h-44 flex items-center justify-center min-h-screen">
            <Bars
              height="40"
              width="40"
              color="#636ab1"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default App;
