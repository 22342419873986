import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import { useAuth } from "../../context/auth";
import { useQuery } from "react-query";
import axios from "axios";
import { Key } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

function preventDefault(event) {
  event.preventDefault();
}

function commafy( num ) {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

export default function Deposits() {
  const navigate = useNavigate()

  let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  var Deposits = 0;
  const OrderData = useQuery(
    ["OrderDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}orders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  OrderData?.data?.data?.data?.map(
    (item, id) => (Deposits = Deposits + item.grandTotalPrice)
  );

  return (
    <React.Fragment>
  {OrderData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {OrderData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (<>
      <Title>Grand Total Sum</Title>
      <Typography component="p" variant="h4">
      <p>  {commafy(Deposits.toFixed(2))} birr</p>
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
       
         on {date}/{month}/{year} 
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={()=> navigate("/orders")}>
          View orders
        </Link>
      </div></>)}
    </React.Fragment>
  );
}
