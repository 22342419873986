import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { useQuery } from "react-query";
import { Bars } from "react-loader-spinner";

// Generate Order Data

export default function Orders() {
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const OrderData = useQuery(
    ["OrderDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}orders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const navigate = useNavigate();
  return (
    <React.Fragment>
        {OrderData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {OrderData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (<>
                <button onClick={() =>  {throw Error("Test Error")}}>Break the world from Gebeya.</button>

      <Title>Recent Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Ordered by</TableCell>
            <TableCell>Order Status</TableCell>
            <TableCell align="right" >Total Price</TableCell>
            <TableCell align="right">Grand Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {OrderData?.data?.data?.data.map((item,i) => {
             return (i < 5) ?<TableRow key={item.id}>
             <TableCell>{item.created_at}</TableCell>
             <TableCell>{item.user?.name}</TableCell>
             <TableCell>{item.order_status}</TableCell>
             <TableCell align="right">{`${item.totalPrice} Birr`}</TableCell>
             <TableCell align="right">{`${item.grandTotalPrice} Birr`}</TableCell>
           </TableRow> : null 
            }).filter(x=>x)}
            
 
        </TableBody>
      </Table>
      <Link
        color="primary"
        href="#"
        onClick={() => navigate("/orders")}
        sx={{ mt: 3 }}
      >
        See more orders
      </Link></>)}
    </React.Fragment>
  );
}
