import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { useQuery } from "react-query";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState } from "react";
import LineChart from "./LineChart";
import { Bars } from "react-loader-spinner";
import { useEffect } from "react";

// Resolves charts dependancy
charts(FusionCharts);

export default function Charts() {
  const { token, user } = useAuth();

  const [chartData, setChartData] = useState(null);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const OrderData = useQuery(
    ["OrderDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}orders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (response) => {
        console.log(response);
        let tempLabels = [];
        let tempData = [];

        console.log(response?.data?.data);
        response?.data?.data?.map((item,i) => {if(i<30) {
          tempLabels.push(item.created_at);
          tempData.push(item.grandTotalPrice);}
        });
        setChartData({
          labels: [...tempLabels],
          datasets: [
            {
              label: "Yeabrak Gained ",
              data: [...tempData],
              backgroundColor: [
                "rgba(75,192,192,1)",

                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

 

  return (
    <>
      {OrderData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {OrderData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>{chartData && <LineChart chartData={chartData} />}</>
      )}
    </>
  );
}
