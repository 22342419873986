import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/auth";

const ChangePassword = ({ ProfileData, handleClosePass }) => {
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editProfileMutation = useMutation(
    async (editProfile) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/change-password`,
        formData,
        config
      )
  );

  const editProfileHandler = async (newData) => {
    try {
        formData.append("current_Password", currentPassword);
        formData.append("new_password", newPassword);
        formData.append("confirm_new_password", confirmPassword);
        

      editProfileMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("success");
            setCurrentPassword(null)
            setNewPassword(null)
            setConfirmPassword(null)
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword:"",
        confirmPassword:""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current Password is required"),
      newPassword: Yup.string().required("This field is required"),
      confirmPassword:  Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ) 
    }),
    onSubmit: (values) => {
      editProfileHandler(ProfileData?.id);
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6  mx-auto max-w-4xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className=" relative flex flex-col w-[100%] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Change Password</h3>
                  <button
                  className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => handleClosePass()}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
                </div>
                {/*body*/}
                <div className="relative p-20 flex-auto">
                  <label className="block text-black text-sm font-base mb-1">
                    Current Password
                  </label>
                  <input
                    id="currentPassword"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                   type="password"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setCurrentPassword(e.target.value);
                    }}
                  />
                  {formik.touched.currentPassword && formik.errors.currentPassword ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.currentPassword}{" "}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    New Password
                  </label>
                  <input
                    id="newPassword"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                   type="password"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setNewPassword(e.target.value);
                    }}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.newPassword}{" "}
                    </div>
                  ) : null}
                  
                  <label className="block text-black text-sm font-base mb-1">
                    Confirm Password
                  </label>
                  <input
                    id="confirmPassword"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                   type="password"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setConfirmPassword(e.target.value);
                    }}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.confirmPassword}{" "}
                        </div>
                      ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleClosePass()}
                  >
                    Close
                  </button>
                  <button
                    disabled={editProfileMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editProfileMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default ChangePassword;
