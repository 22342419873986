import React, { useState } from "react";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";

import FAQDelete from "./FAQDelete";
import FAQEdit from "./FAQEdit ";
import { AiFillEye } from "react-icons/ai";
import FAQView from "./FAQView";

const FAQTable = ({
  faq,
  id,
  setDeleteFAQId,
  setEditFAQId,
  setViewFAQId,
  setEditFAId,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  return (
    <tr key={id} class="bg-white border-b ">
      <td class="px-6 py-4">
        <p>{faq?.title?.english}</p>
      </td>
      <td class="px-6 py-4">
        <p>{faq?.title?.amharic}</p>
      </td>

      <td class="px-6 py-4">
        <div className="flex justify-end">
          <div>
            {showDeleteModal ? (
              <>
                <FAQDelete
                  faq={faq}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeleteFAQId={setDeleteFAQId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showViewModal ? (
              <>
                <FAQView
                  faq={faq}
                  setShowViewModal={setShowViewModal}
                  setViewFAQId={setViewFAQId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showEditModal ? (
              <>
                <FAQEdit
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  faq={faq}
                  id={id}
                  setEditFAQId={setEditFAQId}
                  setEditFAId={setEditFAId}
                />
              </>
            ) : null}
          </div>
          <div className="w-32">
            <button
              onClick={() => {
                setShowViewModal(true);
                setViewFAQId(faq?.id);
              }}
            >
              <AiFillEye
                onClick={() => {
                  setShowViewModal(true);
                  setViewFAQId(faq?.id);
                }}
                className="text-gray-500 mr-2 text-2xl"
              />
            </button>
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditFAQId(faq?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteFAQId(faq?.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FAQTable;
