import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/auth";
import Otp from "./Otp";
import { Box, Modal } from "@mui/material";

const Register = ({ handleCloseRegister }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [appKey, setAppKey] = useState("");
  const [type, setType] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const handleOpenOtp = () => setOpenOtp(true);
  const handleCloseOtp = () => setOpenOtp(false);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addRegisterHandler = () => {
    addRegisterMutationSubmitHandler();
  };
  const addRegisterMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}register`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addRegisterMutationSubmitHandler = async (values) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("appKey", appKey);
    formData.append("type", type);
    try {
      addRegisterMutation.mutate(formData, {
        onSuccess: () => {
          toast.success("success", {});
          handleCloseRegister();
          handleOpenOtp();
          setName(null);
          setEmail(null);
          setPassword(null);
          setAppKey(null);
          setType(null);
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "Register failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      appKey: "",
      type: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("phone is required"),
      password: Yup.string().required("password is required"),
      appKey: Yup.string().required("app key is required"),
      type: Yup.string().required("type is required"),
    }),
    onSubmit: (values) => {
      addRegisterHandler();
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center   flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6  mx-auto max-w-8xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className=" relative flex flex-col w-[100%] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Register</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleCloseRegister()}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-20 flex flex-row">
                  <div className="">
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      Name
                    </label>
                    <input
                      id="name"
                      className="shadow appearance-none border rounded w-88 py-2 px-10 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setName(e.target.value);
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.name}{" "}
                      </div>
                    ) : null}
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      email
                    </label>
                    <input
                      id="email"
                      className="shadow appearance-none border rounded w-full py-2 px-10 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setEmail(e.target.value);
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.email}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="px-10">
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      Phone
                    </label>
                    <input
                      id="phone"
                      type="number"
                      className="shadow appearance-none border rounded w-88 py-2 px-10 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setPhone(e.target.value);
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.phone}{" "}
                      </div>
                    ) : null}
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      Password
                    </label>
                    <input
                      id="passwordpassword"
                      type="password"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setPassword(e.target.value);
                      }}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.password}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="mx-10">
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      App Key
                    </label>
                    <input
                      id="email"
                      className="shadow appearance-none border rounded w-full py-2 px-10 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setAppKey(e.target.value);
                      }}
                    />
                    {formik.touched.appKey && formik.errors.appKey ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.appKey}{" "}
                      </div>
                    ) : null}
                    <label className="block text-black text-sm font-base mb-1">
                      <br />
                      Type
                    </label>
                    <input
                      id="type"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setType(e.target.value);
                      }}
                    />
                    {formik.touched.type && formik.errors.type ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.type}{" "}
                      </div>
                    ) : null}
                  </div>
                  <Modal
                    open={openOtp}
                    onClose={handleCloseOtp}
                    aria-labelledby="modal-modal-pass"
                    aria-describedby="modal-modal-description"
                  >
                    <Box>
                      <Otp
                        handleCloseOtp={handleCloseOtp}
                        prevPhone={phone}
                        setPrevPhone={setPhone}
                      />
                    </Box>
                  </Modal>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleCloseRegister()}
                  >
                    Close
                  </button>
                  <button
                    disabled={addRegisterMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {addRegisterMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default Register;
