import axios from "axios";
import React, { useState } from "react";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card } from "@material-tailwind/react";
import { useAuth } from "../../context/auth";
import { Box, Modal, Typography } from "@mui/material";
import EditProfile from "./EditProfile";
import ChangeRole from "./ChangeRole";
import ChangePassword from "./ChangePassword";
import Drawer from "./Drawer";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "70%",
  p: 4,
};
const Profile = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const [deleteProfileId, setDeleteProfileId] = useState(false);
  const [editProfileId, setEditProfileId] = useState(false);

  const [viewProfileId, setViewProfileId] = useState(false);
  const [editExeId, setEditExeId] = useState(false);

  const [openPass, setOpenPass] = React.useState(false);
  const handleOpenPass = () => setOpenPass(true);
  const handleClosePass = () => setOpenPass(false);

  const [openRole, setOpenRole] = React.useState(false);
  const handleOpenRole = () => setOpenRole(true);
  const handleCloseRole = () => setOpenRole(false);

  const [openUpProf, setOpenUpProf] = React.useState(false);
  const handleOpenUpProf = () => setOpenUpProf(true);
  const handleCloseUpProf = () => setOpenUpProf(false);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ProfileData = useQuery(
    ["ProfileDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}user`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  return (
    <div className="">
      <h2 class="mb-4 ml-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl">
        Profile
      </h2>
      {/* modal */}

      {ProfileData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {ProfileData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div className=" w-[100%]    flex-row grid grid-cols-1 lg:grid-cols-2">
            <div raised={true}>
              <div className="py-6 px-6">
                Name: {ProfileData?.data?.data?.data?.name}
                <br />
                Email: {ProfileData?.data?.data?.data?.email}
                <br />
                Phone: {ProfileData?.data?.data?.data?.phone}
                <br />
                Status: {ProfileData?.data?.data?.data?.status}
                <br />
                Role: {ProfileData?.data?.data?.data?.role?.name}
              </div>
            </div>
            <div>
          
              <button
                className="border bg-[#636ab1] hover:bg-blue-400 text-white p-2  rounded-lg w-56"
                onClick={() => handleOpenPass()}
              >
                Change password
              </button>
              <button
                className="border bg-[#636ab1] hover:bg-blue-400 text-white p-2  rounded-lg w-56 my-4"
                onClick={() => handleOpenRole()}
              >
                Change Role
              </button>
              <button
                className="border bg-[#636ab1] hover:bg-blue-400 text-white p-2  rounded-lg w-56"
                onClick={() => handleOpenUpProf()}
              >
                Update Profile
              </button>
            </div>
            <Modal
              open={openPass}
              onClose={handleClosePass}
              aria-labelledby="modal-modal-pass"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <ChangePassword
                  ProfileData={ProfileData?.data?.data?.data}
                  handleClosePass={handleClosePass}
                />
              </Box>
            </Modal>
            <Modal
              open={openRole}
              onClose={handleCloseRole}
              aria-labelledby="modal-modal-role"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <ChangeRole
                  ProfileData={ProfileData?.data?.data?.data}
                  handleCloseRole={handleCloseRole}
                />
              </Box>
            </Modal>
            <Modal
              open={openUpProf}
              onClose={handleCloseUpProf}
              aria-labelledby="modal-modal-UpProf"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <EditProfile
                  ProfileData={ProfileData?.data?.data?.data}
                  handleCloseUpProf={handleCloseUpProf}
                />
              </Box>
            </Modal>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Profile;
