import React, { useState } from "react";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";

import PrivacyDelete from "./PrivacyDelete";
import PrivacyEdit from "./PrivacyEdit";
import PrivacyView from "./PrivacyView";
import { AiFillEye } from "react-icons/ai";


const PrivacyTable = ({
  privacy,
  id,
  setDeletePrivacyId,
  setEditPrivacyId,
  setViewPrivacyId,
  setEditPriId,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  return (
    <tr key={id} class="bg-white border-b ">
      <td class="px-6 py-4">
        <p>{privacy?.title?.english}</p>
      </td>
      <td class="px-6 py-4">
        <p>{privacy?.title?.amharic}</p>
      </td>

      <td class="px-6 py-4">
        <div className="flex justify-end">
          <div>
            {showDeleteModal ? (
              <>
                <PrivacyDelete
                  privacy={privacy}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeletePrivacyId={setDeletePrivacyId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showEditModal ? (
              <>
                <PrivacyEdit
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  privacy={privacy}
                  id={id}
                  setEditPrivacyId={setEditPrivacyId}
                  setEditPriId={setEditPriId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showViewModal ? (
              <>
                <PrivacyView
                  privacy={privacy}
                  setShowViewModal={setShowViewModal}
                  setViewPrivacyId={setViewPrivacyId}
                />
              </>
            ) : null}
          </div>
          <div className="w-32">
          <button
              onClick={() => {
                setShowViewModal(true);
                setViewPrivacyId(privacy?.id);
              }}
            >
              <AiFillEye
                onClick={() => {
                  setShowViewModal(true);
                  setViewPrivacyId(privacy?.id);
                }}
                className="text-gray-500 mr-2 text-2xl"
              />
            </button>
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditPrivacyId(privacy?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeletePrivacyId(privacy?.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PrivacyTable;
