import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import SideBar from "../../components/SideBar";
import {VscThreeBars} from "react-icons/vsc"
import {BsArrowBarLeft} from "react-icons/bs"
const Drawers = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    ><div className="w-[100%]  flex justify-end">
      <button
        className="text-3xl pr-4 pt-2 text-bold"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <BsArrowBarLeft/>
      </button></div>
      <SideBar />
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
           

          <button className="text-[#636ab1] text-4xl ml-4  flex justify-start " onClick={toggleDrawer(anchor, true)}><  VscThreeBars/></button>
            
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Drawers;
